import { Component, OnDestroy, ViewChild, Input } from '@angular/core';


import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { AJWebService } from '../services/ajWeb.service';
import { Subject, Subscription } from 'Rxjs';
import * as jwt_decode from "jwt-decode";
import { NgxSpinnerService } from "ngx-spinner";

declare var bootbox: any;

import { ActivatedRoute, Router } from '@angular/router';
import { AppConfigService } from 'src/services/app-config.service';
import { HomeComponent } from 'src/components/home/home.component';
import { filter, sample, takeUntil } from 'rxjs/operators';
import { AuthenticationResult, AuthError, EventMessage, EventType } from '@azure/msal-browser';
import { data } from 'jquery';

declare var userNotification: any;

enum UserRoleType {
  AdminSuper = 'AA',
  AdminAccenture = 'AC',
  AdminAvanade = 'AV',
  PortfolioEditor = 'PE',
  PortfolioViewer = 'PV',
  DeliveryLeadEditor = 'DE',
  DeliveryLeadViewer = 'DV',
  ProjectManager = 'PM',
  TeamMember = 'TM',
  ConfigurationManager = 'CM'
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnDestroy {


  private readonly _destroying$ = new Subject<void>();
  private subscription: Subscription;


  public E2E: any;



  public ProjectId: string;
  public isAuthorised: boolean;
  public isActive: boolean;
  public IsScopeApplied: boolean;

  public scopeSelectortoggle: boolean = false;
  //public roleType: UserRoleType;
  public contractOpportunityInitiativeGroups: any;
  public currentSelectedEndToEnd: any;
  public currentSelectedCMS: any;
  public currentSelectedWorkstream: any;
  public showReviewPortfolio: boolean;
  public showManageProject: boolean;
  public showExecuteTasks: boolean;
  public DeliveryConstructAttributeForCMS: any;

  public showScopeSelector: string = "";
  public clickedonheadervalues: boolean = true;
  public token: any;
  public environmentDetails: any;

  public isIframe: boolean;
  public payload_token: any;
  clientNameLimit: string;
  endToEndNameLimit: string;
  scopeDisplayTitleLimit: string;
  public AccessToEndToEndUIds = [];
  public EndToEnds = [];
  public OGSelectedValue : any;
  public OUSelectedValue : any;
  public CSGSelectedValue : any;
  public SelectedOG = null;
  public SelectedOU = null;
  public SelectedCSG = null;
  public FilteredEndToEndUIds :any;
  public ClientList: any;
  public ddData :any;
  //public OG:any;
 // public selectedValue :any;
  public dropDownData:any;
  public OGOUCSGDetails :any;
  public OGKeys = [];
  public OUKeys = []; 
  public CSGKeys = [];
  public OG: any;
  public OU:any;
  public CSG:any;

  public Contracts :any;
  public OperatingGroup: any;
  public OperatingUnit: any;
  public ClientServiceGroup: any;
  // public OGSelectedValue : string=null;
  // public OUSelectedValue : string= null;
  // public CSGSelectedValue : string= null;

  constructor(public ajWebService: AJWebService, private broadcastService: MsalBroadcastService, private authMSALService: MsalService, private SpinnerService: NgxSpinnerService, private route: Router, private activatedRoute: ActivatedRoute, private appConfig: AppConfigService) {
    //  This is to avoid reload during acquireTokenSilent() because of hidden iframe
    //this.ajWebService.endToEndUId = window.location.href.substr(window.location.href.lastIndexOf(':') + 1, 10);
    //localStorage.setItem("endToEndUId", this.ajWebService.endToEndUId);
    this.isIframe = window !== window.parent && !window.opener;

    this.ajWebService.CMSUId = ((this.currentSelectedCMS != undefined && this.currentSelectedCMS.CMSUId != undefined) ? this.currentSelectedCMS.CMSUId : "null");
    this.ajWebService.WorkstreamUId = ((this.currentSelectedWorkstream != undefined && this.currentSelectedWorkstream.WorkstreamUId != undefined) ? this.currentSelectedWorkstream.WorkstreamUId : "null");
    //Cofidentiality Popup
    //this.initializeUserNotificationContent();
  }
  isLoggedIn(): boolean {

    return this.authMSALService.instance.getActiveAccount() != null
  }
  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  ngOnInit(): void {
    //userNotification.showUserNotificationModalPopup();

    this.environmentDetails = this.appConfig.environmentSettings;

    if (this.environmentDetails.MSALenabled == true) {
      console.log('************Welcome AJ PAM ******');
      //MSAL 2.0 code 
      if (!this.isLoggedIn()) {
        console.log('************ user not logged in .. enter login details ******');
        this.authMSALService.instance.loginRedirect();
        this.authMSALService.instance.handleRedirectPromise().then(res => {
          if (res != null && res.account != null) {
            this.E2E='061eea39-86b2-4759-ad11-5f251f688d7f'
            sessionStorage.setItem('E2E', this.E2E);
            this.authMSALService.instance.setActiveAccount(res.account);
            console.log(res);
            const id_token = res.idToken;
            const access_token = res.accessToken;
            //'*****************....Sart....************************'

            //'******************...END....*************************'
            sessionStorage.setItem('token', JSON.stringify(id_token));
            this.payload_token = this.getDecodedAccessToken(id_token); // decodeing tokan here
            sessionStorage.setItem('userId', this.payload_token.preferred_username);
            //console.log('userName---', this.payload_token.preferred_username);
            this.ajWebService.enterpriseId = res.account.username.split('@')[0];
            localStorage.setItem('msal.idtoken', JSON.stringify(id_token));
            if (localStorage.getItem('msal.idtoken') != undefined && localStorage.getItem('msal.idtoken') != '')
              this.ajWebService.showConfidentialityMessage('You are using an Accenture proprietary tool ("myWizard"). You understand that this tool may contain  <strong>ACCENTURE, CLIENT, OR VENDOR / PARTNER CONFIDENTIAL INFORMATION</strong>. You are obligated to adhere to applicable confidentiality obligations and Accenture’s policies, including Policy 69 – Confidentiality, when using the tool and information in the tool.  Information in the tool can only be shared with those authorized to receive it. If you are downloading/exporting Confidential Information to another file or report, you must label that file or report as Accenture Confidential. If you have any questions, please email your question to <a href="mailto:confidentiality@accenture.com?cc=AutomationJourney_Dev@accenture.com&subject=Confidential Information Query (Asset: myWizard)&body=Hi, %0A%0AI have the below query, could you please help on this %0A%0A[TYPE YOUR QUERY HERE]">confidentiality@accenture.com</a> or contact your Leadership. Thank you!');

            if (id_token != undefined && id_token != '') {
              this.loadClientHierarchy();
            }
            else {
              console.log('*****id_token check id_token!=undefined && id_token************');
            }
          }
        }).catch(error => {
          console.error(error);
        });
      }
      else {
        console.log('*****User Already login************');
        this.E2E='061eea39-86b2-4759-ad11-5f251f688d7f'
        sessionStorage.setItem('E2E', this.E2E);
        if (localStorage.getItem('msal.idtoken') != undefined && localStorage.getItem('msal.idtoken') != '')
          this.ajWebService.showConfidentialityMessage('You are using an Accenture proprietary tool ("myWizard"). You understand that this tool may contain  <strong>ACCENTURE, CLIENT, OR VENDOR / PARTNER CONFIDENTIAL INFORMATION</strong>. You are obligated to adhere to applicable confidentiality obligations and Accenture’s policies, including Policy 69 – Confidentiality, when using the tool and information in the tool.  Information in the tool can only be shared with those authorized to receive it. If you are downloading/exporting Confidential Information to another file or report, you must label that file or report as Accenture Confidential. If you have any questions, please email your question to <a href="mailto:confidentiality@accenture.com?cc=AutomationJourney_Dev@accenture.com&subject=Confidential Information Query (Asset: myWizard)&body=Hi, %0A%0AI have the below query, could you please help on this %0A%0A[TYPE YOUR QUERY HERE]">confidentiality@accenture.com</a> or contact your Leadership. Thank you!');

        this.token = localStorage.getItem("msal.idtoken");
        this.payload_token = this.getDecodedAccessToken(this.token); // decodeing tokan here
        this.ajWebService.enterpriseId = this.payload_token.preferred_username.split('@')[0];
        this.loadClientHierarchy();
      } 
    }
    else {
      //this.E2E = window.location.href.split("/").pop();
      this.E2E='061eea39-86b2-4759-ad11-5f251f688d7f'
      sessionStorage.setItem('E2E', this.E2E);
      this.ajWebService.enterpriseId = 'pravalika.pogula';
      this.loadClientHierarchy();
    //  this.getOGOUCSG(this.ajWebService.enterpriseId);

      //this.populateClientDetails();
      //this.route.navigate(["/home"]);
    }
   //this.getOGOUCSG(this.ajWebService.enterpriseId);
   this.ddData= [{"id":1, Name:'pravalika'}, {"id":1, Name:"pravalika1"},{"id":1, "Name":"pravalika2"}]
  // this.selectedValue= "1";
  }

  getOGOUCSG(data) {
    this.SpinnerService.show();
      this.OGOUCSGDetails = data.OGOUCSGDetails;
      var obj=this;
      obj.OG = "";
      obj.OU="";
      obj.CSG="";
      obj.OGKeys=[]; 
      obj.OUKeys=[];
      obj.CSGKeys=[];
      this.OGOUCSGDetails.forEach((item) => {
        if (obj.OG == "" && item.OG != "") {
          obj.OG = [item.OG];
          obj.OGKeys.push(item.OG);
      }
      if (obj.OGKeys.indexOf(item.OG) === -1 && item.OG != "") {
        obj.OGKeys.push(item.OG);
        obj.OG.push(item.OG);
       }

       if (obj.OU == "" && item.OU != "") {
        obj.OU = [item.OU];
        obj.OUKeys.push(item.OU);
    }
    if (obj.OUKeys.indexOf(item.OU) === -1 && item.OU != "") {
        obj.OUKeys.push(item.OU);
        obj.OU.push(item.OU);
    }

    if (obj.CSG == "" && item.CSG != "") {
        obj.CSG = [item.CSG];
        obj.CSGKeys.push(item.CSG);
    }

    if (obj.CSGKeys.indexOf(item.CSG) === -1 && item.CSG != "") {
        obj.CSGKeys.push(item.CSG);
        obj.CSG.push(item.CSG);
    }  
      })

           
    this.OperatingGroup = obj.OG.sort();//OGOUCSGData;
    this.OperatingUnit =obj.OU.sort();//OGOUCSGData;;
    this.ClientServiceGroup =obj.CSG.sort(); 
  }
  

  loadClientHierarchy() {
    this.getClientHierarchy("loadScopeSelector");
  }
  getClientHierarchy(arg0: string) {
    var obj = this;
    this.SpinnerService.show();
    this.ajWebService.endToEndUId = this.E2E;  
   this.ajWebService.getClientHierarchy(obj.ajWebService.enterpriseId, obj.ajWebService.endToEndUId.toLowerCase(), null)
      .subscribe(data => {
        this.Contracts = data;
        this.getALLEndToEnd(obj.Contracts);
        this.LoadSummaryGraphAndDDL(obj.EndToEnds,obj.AccessToEndToEndUIds, obj.OGSelectedValue, obj.OUSelectedValue, obj.CSGSelectedValue);
        this.SpinnerService.hide();
      },
        error => {
          this.SpinnerService.hide();
          console.log(error)
        })
  }
  LoadSummaryGraphAndDDL(EndToEnds,AccessToEndToEndUIds, selectedOG, selectedOU, selectedCSG) 
 {  
  this.SpinnerService.show();
  var obj= this;
  obj.ajWebService.GetSavingsDetails(AccessToEndToEndUIds, selectedOG, selectedOU, selectedCSG)
  .subscribe(data => {
   this.getOGOUCSG(data);
   this.SpinnerService.show();
   obj.ajWebService.getReport(EndToEnds.slice(0, 3)).subscribe(data => {
    this.ClientList=JSON.parse(data as string);
    this.SpinnerService.hide();
   },
     error => {
       this.SpinnerService.hide();
       console.log(error)
     })
  //  this.SpinnerService.hide();
  },
    error => {
      this.SpinnerService.hide();
      console.log(error)
    })
    
 
  }

  getALLEndToEnd(Contracts: any) {
    var obj= this;
    Contracts.Clients.forEach((client, key) => {
      client.ContractOpportunityInitiativeGroups.forEach((COIG, key) => {
        if (COIG.IsGroup == true) {
          COIG.ContractOpportunityInitiatives.forEach((COI,key) => {
            if (obj.AccessToEndToEndUIds.indexOf(COI.EndToEndUId.toLowerCase()) == -1) {
              obj.AccessToEndToEndUIds.push(COI.EndToEndUId.toLowerCase());
              obj.EndToEnds.push({
                  'EndToEndUId': COI.EndToEndUId, 'EndToEndName': COI.EndToEndRefCode != '' ? COI.EndToEndName + '-' + COI.EndToEndRefCode: COI.EndToEndName, 'ClientName': client.Name
             });
          }
          
        })
        }
      else{
        if (obj.AccessToEndToEndUIds.indexOf(COIG.EndToEndUId.toLowerCase()) == -1) {
          obj.AccessToEndToEndUIds.push(COIG.EndToEndUId.toLowerCase());
          obj.EndToEnds.push({ 'EndToEndUId': COIG.EndToEndUId, 'EndToEndName': COIG.EndToEndRefCode != '' ? COIG.EndToEndName + '-' + COIG.EndToEndRefCode : COIG.EndToEndName, 'ClientName': client.Name });
      }

      }
      })
    }
    )}

    OGbehaviour(selectedData){
      this.OGSelectedValue = this.SelectedOG= selectedData;
      this.LoadSummaryGraphAndDDL(this.EndToEnds,this.AccessToEndToEndUIds, this.OGSelectedValue, this.OUSelectedValue, this.CSGSelectedValue);
    }

    OUbehaviour(selectedData){
      this.OUSelectedValue= this.SelectedOU = selectedData;
      this.LoadSummaryGraphAndDDL(this.EndToEnds,this.AccessToEndToEndUIds, this.OGSelectedValue, this.OUSelectedValue, this.CSGSelectedValue);
    }

    CSGbehaviour(selectedData){
      this.CSGSelectedValue =this.SelectedCSG=selectedData;
      this.LoadSummaryGraphAndDDL(this.EndToEnds,this.AccessToEndToEndUIds, this.OGSelectedValue, this.OUSelectedValue, this.CSGSelectedValue);
    }

  


  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (e) {
      //console.log(e);
      return null;

    }
  }

  logout() {
    sessionStorage.clear();
    localStorage.clear();
    this.authMSALService.logout();
  }
  ngOnDestroy() {
    this.broadcastService.msalSubject$;
    if (this.subscription)
      this.subscription.unsubscribe();
  }


  
}
